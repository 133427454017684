import axios from "@/config/axios";

const initialState = () => {
  return {
    allCategories: [],
    allDocuments: [],
    folderCreateStatus: false,
    uploadFileData: {},
    uploadFileStatus: false,
    downloadFileData: {},
    deleteFileStatus: false,

    folderCreateValidationErrors: null,
    fileCreateValidationErrors: null,
  };
};

const state = initialState();

const getters = {
  getAllCategories: (state) => state.allCategories,
  getAllDocuments: (state) => state.allDocuments,
  getFolderCreateStatus: (state) => state.folderCreateStatus,
  getUploadFileData: (state) => state.uploadFileData,
  getUploadFileStatus: (state) => state.uploadFileStatus,
  getDownloadFileData: (state) => state.downloadFileData,
  getDeleteFileStatus: (state) => state.deleteFileStatus,

  getFolderCreateValidationErrors: (state) =>
    state.folderCreateValidationErrors,
  getFileCreateValidationErrors: (state) => state.fileCreateValidationErrors,
};

const mutations = {
  setAllCategories(state, categories) {
    state.allCategories = categories;
  },
  setAllDocuments(state, documents) {
    state.allDocuments = documents;
  },
  setFolderCreateStatus(state, status) {
    state.folderCreateStatus = status;
  },
  setUploadFileData(state, data) {
    state.uploadFileData = data;
  },
  setUploadFileStatus(state, status) {
    state.uploadFileStatus = status;
  },
  setDownloadFileData(state, data) {
    state.downloadFileData = data;
  },
  setDeleteFileStatus(state, status) {
    state.deleteFileStatus = status;
  },
  setFolderCreateValidationErrors(state, status) {
    state.folderCreateValidationErrors = status;
  },
  setFileCreateValidationErrors(state, status) {
    state.fileCreateValidationErrors = status;
  },
  reset: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

const actions = {
  fetchCategories: async ({ commit, dispatch }, params) => {
    try {
      commit("setFolderCreateValidationErrors", null);

      let response = await axios.get("/categories", { params });

      commit("setAllCategories", response.data);
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        });
      }
    }
  },
  fetchDocuments: async ({ commit, dispatch }, params) => {
    try {
      commit("setFileCreateValidationErrors", null);
      commit("setAllDocuments", []);

      let response = await axios.get("/lab-documents", { params });

      commit("setAllDocuments", response.data);
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response);
      }
    }
  },
  createFolder: async ({ commit, dispatch }, params) => {
    try {
      commit("setFolderCreateValidationErrors", null);
      commit("setFolderCreateStatus", false);

      await axios.post("/categories", params);

      commit("setFolderCreateStatus", true);
    } catch (error) {
      if (error.response) {
        let errorData = await dispatch("errors/errorResponse", error.response, {
          root: true,
        });
        commit("setFolderCreateValidationErrors", errorData);
      }
    }
  },
  uploadFile: async ({ commit, dispatch }, params) => {
    try {
      commit("setFileCreateValidationErrors", null);
      commit("setUploadFileData", {});

      const response = await axios.post(
        "/labs-documents/" + params.category_id,
        params.form_data
      );
      commit("setUploadFileData", response.data.data);
    } catch (error) {
      if (error.response) {
        let errorData = await dispatch("errors/errorResponse", error.response, {
          root: true,
        });
        commit("setFileCreateValidationErrors", errorData);
      }
    }
  },
  updateFileStatus: async ({ commit, dispatch }, params) => {
    try {
      commit("setUploadFileStatus", false);

      await axios.patch("/labs-documents/" + params.document_id + "/status");
      commit("setUploadFileStatus", true);
    } catch (error) {
      if (error.response) {
        dispatch("errors/errorResponse", error.response, {
          root: true,
        });
      }
    }
  },
  fetchDownloadFileData: async ({ commit, dispatch }, params) => {
    try {
      commit("setDownloadFileData", {});

      const response = await axios.get("/labs-documents/download", { params });
      commit("setDownloadFileData", response.data.data);
    } catch (error) {
      if (error.response) {
        dispatch("errors/errorResponse", error.response, {
          root: true,
        });
      }
    }
  },
  deleteDocumentFile: async ({ commit, dispatch }, params) => {
    try {
      commit("setDeleteFileStatus", false);

      await axios.delete("/labs-documents/" + params.document_id);
      commit("setDeleteFileStatus", true);
    } catch (error) {
      if (error.response) {
        dispatch("errors/errorResponse", error.response, {
          root: true,
        });
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
