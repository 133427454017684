import config from "@/config/app"
import store from "@/store"
import moment from "moment"
export default {
  guest(to, from, next) {
    var authData = JSON.parse(
      localStorage.getItem(config.APP_NAME + "_" + config.APP_VERSION)
    )
    var isAuthenticated = authData ? authData.auth.authenticationStatus : false
    next(
      !isAuthenticated
        ? true
        : {
          name: "AllCases",
          query: {
            showPopup: true,
          },
        }
    )
  },

  user(to, from, next) {
    try {
      const userData = store.getters["auth/getAuthenticatedUser"]

      if (userData && userData.password_expired_at) {
        const userUpatedPassword = userData.password_expired_at
        const passwordExpireDate =
          moment(userUpatedPassword).format("YYYY-MM-DD")
        const currentDate = moment(new Date()).format("YYYY-MM-DD")
        const dateExpired =
          moment(currentDate).isSameOrAfter(passwordExpireDate)
        if (dateExpired && to.name != "updatePassword") {
          next({ path: "/update-password" })
          return
        }
      } else {
        if (
          userData &&
          !userData.password_expired_at &&
          to.name != "updatePassword"
        ) {
          next({ path: "/update-password" })
          return
        }
      }

      let authDetails = store.getters["auth/getAuthenticationDetails"]

      let accessToken =
        authDetails && authDetails["access_token"]
          ? authDetails["access_token"]
          : null
      var isAuthenticated = accessToken ? true : false
      next(
        isAuthenticated
          ? true
          : {
            path: "/signin",
            query: {
              redirect: to.name,
              params: JSON.stringify(to.params),
              query: JSON.stringify(to.query),
            },
          }
      )
    } catch (err) {
      console.log(err)
    }
  },
  userOrServer(to, from, next) {
    let authDetails = store.getters["auth/getAuthenticationDetails"]
    let accessToken =
      authDetails && authDetails["access_token"]
        ? authDetails["access_token"]
        : null
    var isAuthenticated = accessToken ? true : false
    var server = false
    if (to.query.access_token) {
      let data = {
        access_token: to.query.access_token,
        refresh_token: to.query.refresh_token,
      }
      store.commit("auth/setAuthenticationDetails", data, { root: true })
      server = true
    }
    next(
      isAuthenticated || server
        ? true
        : {
          path: "/signin",
          query: {
            redirect: to.name,
            params: JSON.stringify(to.params),
            query: JSON.stringify(to.query),
          },
        }
    )
  },
  userOrGuest(to, from, next) {
    let authDetails = store.getters["auth/getAuthenticationDetails"]
    let accessToken =
      authDetails && authDetails["access_token"]
        ? authDetails["access_token"]
        : null
    var isAuthenticated = accessToken ? true : false
    var server = false
    if (to.query.access_token) {
      let data = {
        access_token: to.query.access_token,
        refresh_token: to.query.refresh_token,
      }
      store.commit("auth/setAuthenticationDetails", data, { root: true })
      server = true
    }
    next(
      isAuthenticated || server
        ? true
        : {
          path: "/client-pending-orders/signin",
          query: {
            redirect: to.name,
            params: JSON.stringify(to.params),
            query: JSON.stringify(to.query),
          },
        }
    )
  },
  topdog(to, from, next) {
    const authDetails = store.getters["auth/getAuthenticationDetails"]
    const authUser = store.getters["auth/getAuthenticatedUser"]
    let accessToken =
      authDetails && authDetails["access_token"]
        ? authDetails["access_token"]
        : null
    var isAuthenticated = accessToken ? true : false
    let isTopdog = authUser["user_type"] === "TOP_DOG" ? true : false

    if (!isTopdog && isAuthenticated) {
      next({
        path: "/",
      })
    } else {
      next(
        isAuthenticated
          ? true
          : {
            path: "/signin",
            query: {
              redirect: to.name,
              params: JSON.stringify(to.params),
              query: JSON.stringify(to.query),
            },
          }
      )
    }
  },

  qcManager(to, from, next) {
    let authDetails = store.getters["auth/getAuthenticationDetails"]
    let authUser = store.getters["auth/getAuthenticatedUser"]
    let accessToken =
      authDetails && authDetails["access_token"]
        ? authDetails["access_token"]
        : null
    var isAuthenticated = accessToken ? true : false
    let isQCManager =
      ["QC_MANAGER", "LAB_MANAGER", "LAB_ADMIN", "SALES_DIRECTOR"].indexOf(
        authUser["user_type"]
      ) > -1
        ? true
        : false
    if (to.query.access_token) {
      let data = {
        access_token: to.query.access_token,
        refresh_token: to.query.refresh_token,
      }
      store.commit("auth/setAuthenticationDetails", data, { root: true })
    }

    if (!isQCManager && isAuthenticated) {
      next({
        path: "/",
      })
    } else {
      next(
        isAuthenticated
          ? true
          : {
            path: "/signin",
            query: {
              redirect: to.name,
              params: JSON.stringify(to.params),
              query: JSON.stringify(to.query),
            },
          }
      )
    }
  },
}
