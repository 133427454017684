import * as PrivateAxios from "axios"
import moment from "moment-timezone"
import axios from "@/config/axios"

const initialState = () => {
  return {
    allCases: [],
    globalSearchCases: [],
    caseInformation: {},
    singleCase: null,
    caseInformationValidationErrors: {},
    caseInformationValidationErrorsCode: {},
    patientInformationValidationErrors: {},
    caseInformationSaveStatus: false,
    patientInformationData: {},
    patientInformationUpdateStatus: false,
    caseDeleteStatus: false,
    caseDeleteError: null,
    attachmentUploadURL: null,
    attachmentFileId: null,
    attachmentUploadStatusUpdated: false,
    attachmentUploadStatus: false,
    attachmentUploadStatusForAttachments: false,

    attachmentDeleteStatus: false,

    internalDocumentDeleteStatus: false,

    noteAddStatus: false,
    noteUpdateStatus: false,
    testPerformedUpdateStatus: false,
    noteDeleteStatus: false,
    noteValidationErrors: {},
    barCodeURL: "",
    barCodeGenerateStatus: false,
    printedBarCodeUploadStatus: false,

    testResultsUpdateStatus: false,
    updateCaseStatus: null,

    testReportURL: "",
    reportGenerateStatus: false,
    reportDeleteStatus: false,
    additionalReports: false,
    mergeAdditionalReports: false,
    testReportsData: [],
    reportPhysicianStatusUpdated: false,
    reportTitleUpdated: false,
    reportDownloadStatus: false,

    faxSentStatus: false,

    allCaseErrors: false,
    multipleBarcodeSources: [],
    multipleBarcodeStatus: false,

    restoreCaseStatus: false,
    restoreCaseError: null,

    // Medications
    medicationCreateStatus: false,
    medicationValidationErrors: null,
    allMedications: null,

    // Test Reports upload
    uploadTestReportData: {},
    uploadTestReportStatus: false,
    uploadTestReportValidationErrors: null,
    downloadReportData: null,
    addressVerification: null,

    previewCaseData: null,

    showMedication: false,

    // merge reports after upload
    mergeCaseReportErrors: null,
    mergeCaseReportStatus: false,

    reportNoteUpdated: false,
    newAddedMedication: null,
    corriellTestPanels: null,

    deleteCaseBillingInfoStatus: false,
    downloadCaseDataStatus: false,

    utiCaseCreationStatus: false,
    testResults: null,
    testResultsPreview: null,

    reportPhysicianStatusData: null,
    checkFaxStatus: null,

    bloodTestResults: {},
    testReportPreviewURL: null,
    reportPreviewStatus: false,
    reportSentToPatientStatus: false,
    bloodTestReportGenerateStatus: false,
    multipleReportsUploadData: null,
    reportFileId: null,
    uploadMultipleTestReportStatus: false,
    multipleUploadTestReportStatus: false,
    reportSentToPatientBySMSStatus: false,

    caseCreationStatus: null,
    caseInformationFormErrors: null,

    urineTestResultsUpdateStatus: false,
    urineTestResults: {},
    urineMachineResults: null,
    resultsAddStatus: false,
    urineTestReportGenerateStatus: false,
    testPerformedBy: null,
    stateCity: [],
    address: [],
    testPerformedByDeleteStatus: false,

    toxicResults: {},
    updateToxicologyTestResultStatus: false,
    sampleNameTestResultData: null,
    toxicologyReportGenerateStatus: false,
    toxicologyResultsAddStatus: false,
    testReportsDownloadLog: false,
    testReportsViewLog: false,

    report: null,
    requisitionDownloadStatus: false,

    allSampleTypes: null,
    sendAccessionToLabdaq: false,
    syncAccessionToLabdaq: false,
    physicianValiations: null,
    downloadTrumedDataStatus: false,
    sendToRXNT: false,
    sendToLabdaqValidationError: {},
    addCPT: false,
    cptForAccession: null,
    cptTestInfo: null,
    labdaqPendingOrders: [],
    labdaqPendingResults: [],
    billingCategoryStatus: false,
    allBilledCases: [],
    billingStatusCases: [],
    syncWithTests: [],
    isValidationCases: [],
    updateValidationStatus: false,
    labdaqInvalidOrders: [],
    labdaqCompletedOrders: [],
    bulkValidations: false,
    insuranceEligibility: null,
    insuranceEligibilityDetails: null,
    cptCodesForUTI: [],
    payableICDCodes: [],
    billingAssignStatus: false,
    patientHistory: [],
    bulkBillingAssignStatus: false,
    medicationTests:[],
    billingInfoSwap:false
  }
}

const state = initialState()

const getters = {
  getStateAndCity: (state) => state.stateCity,
  getAddress: (state) => state.address,
  getTestPerformedByStatus: (state) => state.testPerformedBy,
  getCaseInformationValidationErrors: (state) =>
    state.caseInformationValidationErrors,
  getCaseInformationValidationErrorsCode: (state) =>
    state.caseInformationValidationErrorsCode,

  getCaseInformationSaveStatus: (state) => state.caseInformationSaveStatus,
  getAllCases: (state) => state.allCases,
  getGlobalSearchCases: (state) => state.globalSearchCases,
  getAllCaseErrors: (state) => state.allCaseErrors,

  getCaseInformation: (state) => state.caseInformation,
  getCaseCreationStatus: (state) => state.caseCreationStatus,
  getPatientInformationValidationErrors: (state) =>
    state.patientInformationValidationErrors,
  getSingleCase: (state) => state.singleCase,
  getCaseDeleteStatus: (state) => state.caseDeleteStatus,
  getCaseDeleteError: (state) => state.caseDeleteError,

  getFileUploadURLForAttachments: (state) => state.attachmentUploadURL,
  getFileUploadRefIdForAttachment: (state) => state.attachmentFileId,
  getAttachmentUploadStatusUpdated: (state) =>
    state.attachmentUploadStatusUpdated,
  getAttachmentUploadStatus: (state) => state.attachmentUploadStatus,
  getAttachmentUploadStatusForAttachments: (state) =>
    state.attachmentUploadStatusForAttachments,
  getAttachmentDeleteStatus: (state) => state.attachmentDeleteStatus,
  getInternalDocumentDeleteStatus: (state) =>
    state.internalDocumentDeleteStatus,

  getNoteAddStatus: (state) => state.noteAddStatus,
  getNoteUpdateStatus: (state) => state.noteUpdateStatus,
  getTestPerformedUpdateStatus: (state) => state.testPerformedUpdateStatus,
  getNoteDeleteStatus: (state) => state.noteDeleteStatus,
  getNoteValidationErrors: (state) => state.noteValidationErrors,

  getBarCodeGenerateStatus: (state) => state.barCodeGenerateStatus,
  getBarCodeURL: (state) => state.barCodeURL,
  getPrintedBarCodeUploadStatus: (state) => state.printedBarCodeUploadStatus,

  getReportGenerateStatus: (state) => state.reportGenerateStatus,
  getTestReportURL: (state) => state.testReportURL,
  getReportDeleteStatus: (state) => state.reportDeleteStatus,
  getAdditionalReports: (state) => state.additionalReports,
  getMergeAddtionalReports: (state) => state.mergeAdditionalReports,
  getReportPhysicianStatusUpdated: (state) =>
    state.reportPhysicianStatusUpdated,
  getReportPhysicianStatusData: (state) => state.reportPhysicianStatusData,
  getReportTitleUpdated: (state) => state.reportTitleUpdated,
  getTestReportsData: (state) => state.testReportsData,
  getReportDownloadStatus: (state) => state.reportDownloadStatus,

  getFaxSentStatus: (state) => state.faxSentStatus,

  getTestResultsUpdateStatus: (state) => state.testResultsUpdateStatus,

  getUpdateCaseStatus: (state) => state.updateCaseStatus,
  getUpdateCaseCovidStatus: (state) => state.updateCaseCovidStatus,

  getMultipleBarcodeSources: (state) => state.multipleBarcodeSources,
  getMultipleBarcodeStatus: (state) => state.multipleBarcodeStatus,

  getRestoreCaseStatus: (state) => state.restoreCaseStatus,
  getRestoreCaseError: (state) => state.restoreCaseError,

  getMedicationCreateStatus: (state) => state.medicationCreateStatus,
  getMedicationValidationErrors: (state) => state.medicationValidationErrors,
  getNewlyAddMedication: (state) => state.medicationValidationErrors,
  getAllMedications: (state) => state.allMedications,

  getUploadTestReportData: (state) => state.uploadTestReportData,
  getUploadTestReportStatus: (state) => state.uploadTestReportStatus,

  getUploadTestReportValidationErrors: (state) =>
    state.uploadTestReportValidationErrors,
  getDownloadReportData: (state) => state.downloadReportData,
  getVerifyAddress: (state) => state.addressVerification,

  getPreviewCaseData: (state) => state.previewCaseData,
  getShowMedication: (state) => state.showMedication,

  // merge reports after upload
  getMergeCaseReportErrors: (state) => state.mergeCaseReportErrors,
  getMergeCaseReportStatus: (state) => state.mergeCaseReportStatus,

  getReportNoteUpdated: (state) => state.reportNoteUpdated,
  getCorriellTestPanels: (state) => state.corriellTestPanels,

  getDeleteCaseBillingInfoStatus: (state) => state.deleteCaseBillingInfoStatus,
  getDownloadCaseDataStatus: (state) => state.downloadCaseDataStatus,

  getUtiCaseCreationStatus: (state) => state.utiCaseCreationStatus,
  getTestResults: (state) => state.testResults,
  getTestResultsPreview: (state) => state.testResultsPreview,

  getCheckFaxStatus: (state) => state.checkFaxStatus,
  getAllTestResults: (state) => state.bloodTestResults,
  getTestPreviewURL: (state) => state.testReportPreviewURL,
  getReportPreviewStatus: (state) => state.reportPreviewStatus,
  getReportSentToPatientStatus: (state) => state.reportSentToPatientStatus,
  getBloodReportGenerateStatus: (state) => state.bloodTestReportGenerateStatus,

  getMultipleReportsUploadData: (state) => state.multipleReportsUploadData,
  getReportFileId: (state) => state.reportFileId,
  getUploadMultipleTestReportStatus: (state) =>
    state.uploadMultipleTestReportStatus,
  getMultipleUploadStatus: (state) => state.multipleUploadTestReportStatus,
  getReportSentToPatientBySMSStatus: (state) =>
    state.reportSentToPatientBySMSStatus,

  getCaseInformatonFormErrors: (state) => state.caseInformationFormErrors,
  getUrineTestResultsUpdateStatus: (state) =>
    state.urineTestResultsUpdateStatus,
  getUrineTestResults: (state) => state.urineTestResults,
  getUrineMachineResults: (state) => state.urineMachineResults,
  getUrineTestPreviewURL: (state) => state.urineTestReportPreviewURL,
  getResultsAddStatus: (state) => state.resultsAddStatus,
  getUrineReportGenerateStatus: (state) => state.urineTestReportGenerateStatus,
  getTestPerformedByDeleteStatus: (state) => state.testPerformedByDeleteStatus,

  getSampleNameTestResultData: (state) => state.sampleNameTestResultData,
  getToxicologyTestResultUpdateStatus: (state) =>
    state.updateToxicologyTestResultStatus,

  getToxicInformation: (state) => state.toxicResults,
  getToxicologyReportGenerateStatus: (state) =>
    state.toxicologyReportGenerateStatus,
  getToxicologyResultsAddStatus: (state) => state.toxicologyResultsAddStatus,
  getTestReportsDownloadLog: (state) => state.testReportsDownloadLog,
  getTestReportsViewLog: (state) => state.testReportsViewLog,

  getReport: (state) => state.report,
  getDownloadReportStatus: (state) => state.requisitionDownloadStatus,
  getAllSampleTypes: (state) => state.allSampleTypes,
  getSendAccessionToLabdaq: (state) => state.sendAccessionToLabdaq,
  getSyncAccessionToLabdaq: (state) => state.syncAccessionToLabdaq,
  getPhysicianValidations: (state) => state.physicianValiations,
  getDownloadTrumedDataStatus: (state) => state.downloadTrumedDataStatus,
  getRXNTSendStatus: (state) => state.sendToRXNT,
  getSendToLabdaqValiationError: (state) => state.sendToLabdaqValidationError,
  getAddCPT: (state) => state.addCPT,
  getCPTForAccession: (state) => state.cptForAccession,
  getCPTTestInfo: (state) => state.cptTestInfo,
  getLabdaqPendingOrders: (state) => state.labdaqPendingOrders,
  getLabdaqPendingResults: (state) => state.labdaqPendingResults,
  getBillingCategoryUpdateStatus: (state) => state.billingCategoryStatus,
  getAllBilledCases: (state) => state.allBilledCases,
  getBillingStatusCases: (state) => state.billingStatusCases,
  getToxicologyTests: (state) => state.syncWithTests,
  getIsValidationCases: (state) => state.isValidationCases,
  getUpdateValidationStatus: (state) => state.updateValidationStatus,
  getLabdaqInvalidOrders: (state) => state.labdaqInvalidOrders,
  getLabdaqCompletedOrders: (state) => state.labdaqCompletedOrders,
  getBulkValidations: (state) => state.bulkValidations,
  getInsuranceEligibility: (state) => state.insuranceEligibility,
  getInsuranceEligibilityDetails: (state) => state.insuranceEligibilityDetails,
  getCptCodesForUTI: (state) => state.cptCodesForUTI,
  getPayableICDCodes: (state) => state.payableICDCodes,
  getBillingAssignStatus: (state) => state.billingAssignStatus,
  getPatientHistory: (state) => state.patientHistory,
  getBulkBillingAssignStatus: (state) => state.bulkBillingAssignStatus,
  getMedicationTests:(state) => state.medicationTests,
  getBillingInfoSwapStatus: (state) => state.billingInfoSwap
}

const mutations = {
  setTestPerformedByDeleteStatus(state, data) {
    state.testPerformedByDeleteStatus = data
  },
  setStateCity(state, data) {
    state.stateCity = data
  },
  setAddress(state, data) {
    state.address = data
  },

  setTestPerformedBy(state, data) {
    state.testPerformedBy = data
  },
  setCaseInformationValidationErrors(state, data) {
    state.caseInformationValidationErrors = data
  },
  setCaseInformationValidationErrorsCode(state, data) {
    state.caseInformationValidationErrorsCode = data
  },

  setCaseInformationSaveStatus(state, data) {
    state.caseInformationSaveStatus = data
  },

  setAllCases(state, data) {
    state.allCases = data
  },
  setCaseCreationStatus(state, data) {
    state.caseCreationStatus = data
  },
  setGlobalSearchCases(state, data) {
    state.globalSearchCases = data
  },
  setAllCaseErrors(state, data) {
    state.allCaseErrors = data
  },
  singleCase(state, caseData) {
    state.singleCase = caseData
  },

  setCaseInformation(state, data) {
    state.caseInformation = data
  },

  setCaseDeleteError(state, data) {
    state.caseDeleteError = data
  },
  setCaseDeleteStatus(state, data) {
    state.caseDeleteStatus = data
  },

  setFileUploadURLForAttachments(state, data) {
    state.attachmentUploadURL = data
  },
  setFileUploadRefIdForAttachment(state, data) {
    state.attachmentFileId = data
  },

  setAttachmentUploadStatusUpdated(state, data) {
    state.attachmentUploadStatusUpdated = data
  },
  setAttachmentUploadStatus(state, data) {
    state.attachmentUploadStatus = data
  },
  setAttachmentUploadStatusForAttachments(state, data) {
    state.attachmentUploadStatusForAttachments = data
  },
  setAttachmentDeleteStatus(state, data) {
    state.attachmentDeleteStatus = data
  },

  setNoteAddStatus(state, data) {
    state.noteAddStatus = data
  },
  setNoteUpdateStatus(state, data) {
    state.noteUpdateStatus = data
  },
  setTestPerformedUpdateStatus(state, data) {
    state.testPerformedUpdateStatus = data
  },
  setNoteDeleteStatus(state, data) {
    state.noteDeleteStatus = data
  },
  setNoteValidationErrors(state, data) {
    state.noteValidationErrors = data
  },

  setBarCodeGenerateStatus(state, data) {
    state.barCodeGenerateStatus = data
  },
  setReportGenerateStatus(state, data) {
    state.reportGenerateStatus = data
  },
  setReportDeleteStatus(state, data) {
    state.reportDeleteStatus = data
  },
  setBarCodeURL(state, data) {
    state.barCodeURL = data
  },
  setTestReportURL(state, data) {
    state.testReportURL = data
  },

  setTestResultsUpdateStatus(state, data) {
    state.testResultsUpdateStatus = data
  },
  setReportPhysicianStatusUpdated(state, data) {
    state.reportPhysicianStatusUpdated = data
  },
  setReportPhysicianStatusData(state, data) {
    state.reportPhysicianStatusData = data
  },
  setReportPhysicianValiation(state, data) {
    state.physicianValiations = data
  },
  setReportTitleUpdated(state, data) {
    state.reportTitleUpdated = data
  },
  setTestReportsData(state, data) {
    state.testReportsData = data
  },
  setReportDownloadStatus(state, data) {
    state.reportDeleteStatus = data
  },
  setReportAdditionalReports(state, data) {
    state.additionalReports = data
  },

  setMergeAdditionalReports(state, data) {
    state.mergeAdditionalReports = data
  },

  setFaxSentStatus(state, status) {
    state.faxSentStatus = status
  },

  setUpdateCaseStatus(state, status) {
    state.updateCaseStatus = status
  },
  setUpdateCaseCovidStatus(state, status) {
    state.updateCaseCovidStatus = status
  },

  setMultipleBarcodeSources(state, data) {
    state.multipleBarcodeSources = data
  },
  setMultipleBarcodeStatus(state, data) {
    state.multipleBarcodeStatus = data
  },

  setRestoreCaseStatus(state, data) {
    state.restoreCaseStatus = data
  },
  setRestoreCaseError(state, data) {
    state.restoreCaseError = data
  },

  // Medications

  setMedicationCreateStatus(state, status) {
    state.medicationCreateStatus = status
  },
  setNewlyAddedMedication(state, data) {
    state.newAddedMedication = data
  },
  setMedicationValidationErrors(state, errors) {
    state.medicationValidationErrors = errors
  },
  setAllMedications(state, medications) {
    state.allMedications = medications
  },

  setUploadTestReportData(state, data) {
    state.uploadTestReportData = data
  },
  setUploadTestReportStatus(state, status) {
    state.uploadTestReportStatus = status
  },
  setUploadMultipleTestReportData(state, status) {
    state.uploadMultipleTestReportStatus = status
  },
  setUploadStatus(state, status) {
    state.multipleUploadTestReportStatus = status
  },
  setUploadTestReportValidationErrors(state, error) {
    state.uploadTestReportValidationErrors = error
  },
  setDownloadReportData(state, data) {
    state.downloadReportData = data
  },
  setVerifyAddress(state, data) {
    state.addressVerification = data
  },

  setPreviewCaseData(state, data) {
    state.previewCaseData = data
  },
  setShowMedication(state, data) {
    state.showMedication = data
  },
  // merge reports after upload
  setMergeCaseReportErrors(state, errors) {
    state.mergeCaseReportErrors = errors
  },
  setMergeCaseReportStatus(state, status) {
    state.mergeCaseReportStatus = status
  },
  setReportNoteUpdated(state, status) {
    state.reportNoteUpdated = status
  },
  setCorriellTestPanels(state, panels) {
    state.corriellTestPanels = panels
  },
  setDeleteCaseBillingInfoStatus(state, status) {
    state.deleteCaseBillingInfoStatus = status
  },
  setEligibilityStatus(state, status) {
    state.insuranceEligibility = status
  },
  setInsuranceEligibilityDetails(state, data) {
    state.insuranceEligibilityDetails = data
  },
  setDownloadCaseDataStatus(state, status) {
    state.downloadCaseDataStatus = status
  },
  setUTICaseCreationStatus(state, status) {
    state.utiCaseCreationStatus = status
  },
  setTestResults(state, results) {
    state.testResults = results
  },
  setUrineTestResultsUpdateStatus(state, data) {
    state.urineTestResultsUpdateStatus = data
  },
  setUrineTestResults(state, data) {
    state.urineTestResults = data
  },
  setUrineMachineResults(state, data) {
    state.urineMachineResults = data
  },
  setUrineTestReportPreviewURL(state, data) {
    state.urineTestReportPreviewURL = data
  },

  setResultsAddStatus(state, data) {
    state.resultsAddStatus = data
  },
  setTestResultsPreview(state, data) {
    state.testResultsPreview = data
  },
  setCheckFaxStatus(state, status) {
    state.checkFaxStatus = status
  },
  //blood test results
  setBloodTestResults(state, data) {
    state.bloodTestResults = data
  },
  setTestReportPreviewURL(state, data) {
    state.testReportPreviewURL = data
  },
  setReportPreviewStatus(state, data) {
    state.reportPreviewStatus = data
  },
  setReportSentToPatientStatus(state, status) {
    state.reportSentToPatientStatus = status
  },
  setBloodTestReportGenerateStatus(state, data) {
    state.bloodTestReportGenerateStatus = data
  },
  setMultipleReportsUploadData(state, data) {
    state.multipleReportsUploadData = data
  },
  setReportSentToPatientBySMSStatus(state, status) {
    state.reportSentToPatientBySMSStatus = status
  },

  setCaseInformationError(state, data) {
    state.caseInformationFormErrors = data
  },
  setUrineTestReportGenerateStatus(state, data) {
    state.urineTestReportGenerateStatus = data
  },

  setToxicInformation(state, data) {
    state.toxicResults = data
  },
  setUpdateToxicologyTestResultStatus(state, data) {
    state.updateToxicologyTestResultStatus = data
  },
  setSampleNameTestResultData(state, data) {
    state.sampleNameTestResultData = data
  },
  setToxReportGenerateStatus(state, data) {
    state.toxicologyReportGenerateStatus = data
  },
  setToxResultsAddStatus(state, data) {
    state.toxicologyResultsAddStatus = data
  },
  setTestReportDownloadLog(state, status) {
    state.testReportsDownloadLog = status
  },
  setTestReportViewLog(state, status) {
    state.testReportsViewLog = status
  },
  setRequisition(state, report) {
    state.report = report
  },
  setRequisitionDownloadStatus(state, report) {
    state.requisitionDownloadStatus = report
  },
  setSampleTypes(state, data) {
    state.allSampleTypes = data
  },
  setSendAccessionToLabdaq(state, data) {
    state.sendAccessionToLabdaq = data
  },
  setSyncAccessionToLabdaq(state, data) {
    state.syncAccessionToLabdaq = data
  },
  setDownloadTrumedDataStatus(state, data) {
    state.downloadTrumedDataStatus = data
  },
  setRXNTSendStatus(state, data) {
    state.sendToRXNT = data
  },
  setSendToLabdaqValidationError(state, data) {
    state.sendToLabdaqValidationError = data
  },
  setAddCPT(state, data) {
    state.addCPT = data
  },
  setBillingCategoryStatus(state, data) {
    state.billingCategoryStatus = data
  },
  setCPTForAccession(state, data) {
    state.cptForAccession = data
  },
  setTestinforCase(state, data) {
    state.cptTestInfo = data
  },
  setLabdaqPendingOrders(state, data) {
    state.labdaqPendingOrders = data
  },
  setLabdaqPendingResults(state, data) {
    state.labdaqPendingResults = data
  },
  setAllBilledCases(state, data) {
    state.allBilledCases = data
  },
  setBillingStatusCases(state, data) {
    state.billingStatusCases = data
  },
  setToxicologyTests(state, data) {
    state.syncWithTests = data
  },
  setIsValidationCases(state, data) {
    state.isValidationCases = data
  },
  setUpdateValidationStatus(state, data) {
    state.updateValidationStatus = data
  },
  setLabdaqInvalidOrders(state, data) {
    state.labdaqInvalidOrders = data
  },
  setLabdaqCompletedOrders(state, data) {
    state.labdaqCompletedOrders = data
  },
  setBulkValidations(state, data) {
    state.bulkValidations = data
  },
  setCptCodesForUTI(state, data) {
    state.cptCodesForUTI = data
  },
  setPayableICDCodes(state, data) {
    state.payableICDCodes = data
  },
  setBillingAssignStatus(state, status) {
    state.billingAssignStatus = status
  },
  setBulkBillingAssignStatus(state, status) {
    state.bulkBillingAssignStatus = status
  },
  setPatientHistory(state, data) {
    state.patientHistory = data
  },
  setMedicationTests(state, data){
    state.medicationTests = data
  },  
  setBillingInfoSwap(state, data) {
    state.billingInfoSwap = data
  },
  reset(state) {
    const initial = initialState()
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key]
    })
  },
}
const actions = {
  fetchAllCases: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", [], { root: true })

      const response = await axios.get("/cases", { params })

      commit("setAllCases", response.data)
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
  fetchAllSampleTypes: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", [], { root: true })
      const response = await axios.get("/sample-types", { params })
      commit("setSampleTypes", response.data.data)
    } catch (err) {
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true })
      }
    }
  },
  createSampleTypes: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", [], { root: true })
      await axios.post("/sample-types", params)
      // commit("setSampleTypes",response.data.data)
    } catch (err) {
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true })
      }
    }
  },
  sendAccessionToLabdaq: async ({ commit, dispatch }, params) => {
    try {
      commit("setSendAccessionToLabdaq", false)
      commit("setSendToLabdaqValidationError", {})
      await axios.post("/lab-daq/" + params.case_id + "/send-order", params)
      commit("setSendAccessionToLabdaq", true)
    } catch (err) {
      if (err.response) {
        commit("setSendAccessionToLabdaq", false)
        commit("setSendToLabdaqValidationError", err.response.data)
        await dispatch("errors/errorResponse", err.response, { root: true })
      }
    }
  },
  synsAccessionToLabdaq: async ({ commit, dispatch }, params) => {
    try {
      commit("setSyncAccessionToLabdaq", false)
      await axios.post("/lab-daq/" + params.case_id + "/sync-order", params)
      commit("setSyncAccessionToLabdaq", true)
    } catch (err) {
      if (err.response) {
        commit("setSyncAccessionToLabdaq", false)
        await dispatch("errors/errorResponse", err.response, { root: true })
      }
    }
  },

  fetchRequisitionById: async ({ commit, dispatch }, params) => {
    try {
      let caseId = params.caseId
      delete params.caseId
      commit("setRequisition", null)
      commit("errors/clear", null, { root: true })
      const response = await axios.get(`cases/${caseId}/e-requisition`, {
        params,
      })

      commit("setRequisition", response.data)
    } catch (err) {
      commit("setRequisition", null)
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true })
      }
    }
  },

  downloadRequisitionForm: async ({ commit, dispatch }, params) => {
    try {
      let case_id = params.caseId
      delete params.caseId
      commit("setRequisitionDownloadStatus", false)
      commit("errors/clear", null, { root: true })
      const response = await axios.get("cases/" + case_id + "/e-requisition", {
        params,
      })
      let data = response.data
      const linkSource = `data:application/pdf;base64,${data}`
      const donwloadLink = document.createElement("a")

      const fileName = "requisition.pdf"
      donwloadLink.href = linkSource
      donwloadLink.download = fileName
      donwloadLink.click()

      commit("setRequisitionDownloadStatus", true)
    } catch (err) {
      commit("setRequisitionDownloadStatus", null)
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true })
      }
    }
  },
  getStateCity: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", [], { root: true })

      let response = []
      commit("setStateCity", [])
      if (params.zip.length == 5) {
        response = await axios.get("/get-state-city", { params })
      }

      commit("setStateCity", response.data.data)
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
  getAddress: async ({ commit, dispatch }, query) => {
    try {
      await dispatch("errors/clear", [], { root: true })

      let response = []
      commit("setAddress", [])

      response = await axios.get(
        // "https://api-placeslabsquire-development-cdbb.up.railway.app/v1.0/search-place",
        "/google-places",
        {
          params: { query },
          // headers: {
          //   Authorization:
          //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzNjQ5MmJhNzRiOWE3MDJiYTJkNzdiYiIsImVtYWlsIjoiYWpheXJlZGR5QGdtYWlsLmNvbSIsInVzZXJfdHlwZSI6IkxBQl9BRE1JTiIsImZpcnN0X25hbWUiOiJhamF5IiwibGFzdF9uYW1lIjoicmVkZHkiLCJpYXQiOjE2NzU5MjMwOTYsImV4cCI6MTY3NjUyNzg5Nn0.n1TGdgNR4mqGLHsK8Ah7NaBFfllScgbdfwF0ORcX48o",
          // },
        }
      )

      commit("setAddress", response.data.data)
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
  fetchAddressVerification: async ({ commit, dispatch }, params) => {
    try {
      commit("setVerifyAddress", null)
      let response = await axios.post("/verify-address", params)
      commit("setVerifyAddress", response.data)
    } catch (error) {
      commit("setVerifyAddress", null)
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
  updateTestPerformedBy: async ({ commit, dispatch }, params) => {
    try {
      let case_id = params.case_id
      delete params.case_id
      let performed_id = params.performed_id
      delete params.performed_id
      commit("setTestPerformedUpdateStatus", false)
      await axios.patch(
        "cases/" + case_id + "/test-performed/" + performed_id,
        params
      )
      commit("setTestPerformedUpdateStatus", true)
    } catch (error) {
      commit("setTestPerformedUpdateStatus", false)
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
  addTestPerformedBy: async ({ commit, dispatch }, params) => {
    try {
      let case_id = params.case_id
      delete params.case_id
      commit("setTestPerformedBy", null)

      const response = await axios.post(
        "/cases/" + case_id + "/test-performed",
        params
      )
      const data = response.data.data
      commit("setTestPerformedBy", data)
    } catch (error) {
      console.log(error)
    }
  },
  deleteTestPerformedBy: async ({ commit }, params) => {
    try {
      let case_id = params.case_id
      delete params.case_id
      let test_performed_id = params.test_performed_id
      delete params.test_performed_id
      commit("setTestPerformedByDeleteStatus", false)
      await axios.delete(
        "/cases/" + case_id + "/test-performed/" + test_performed_id,
        { data: params }
      )
      commit("setTestPerformedByDeleteStatus", true)
    } catch (error) {
      commit("setTestPerformedByDeleteStatus", false)
    }
  },
  fetchCaseDetailsByAccession: async (context, accession_id) => {
    try {
      context.commit("cases/singleCase", null, {
        root: true,
      })
      let response = await axios.get(`/cases/${accession_id}/accession`)
      context.commit("cases/singleCase", response.data.data, {
        root: true,
      })
    } catch (error) {
      context.dispatch("errors/errorResponse", error.response, {
        root: true,
      })
    }
  },
  addNewCase: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", [], { root: true })
      commit("setCaseInformation", null)
      commit("setCaseCreationStatus", null)
      commit("setCaseInformationValidationErrorsCode")
      const response = await axios.post("/cases", params)

      commit("setCaseInformation", response.data)
      commit("setCaseCreationStatus", response.data)
    } catch (error) {
      if (error.response) {
        commit("setCaseInformationError", error.response)
        commit("setCaseInformationValidationErrorsCode", error.response.data)
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
  createNewCase: async (context, params) => {
    try {
      context.commit(
        "cases/setCaseInformationValidationErrors",
        {},
        {
          root: true,
        }
      )
      context.commit(
        "cases/setCaseInformationValidationErrorsCode",
        {},
        { root: true }
      )
      context.commit("cases/setCaseInformationSaveStatus", false, {
        root: true,
      })
      const response = await axios.post("/cases", params)
      context.commit("cases/setCaseInformation", response.data.data, {
        root: true,
      })
      context.commit("cases/setPreviewCaseData", response.data.data, {
        root: true,
      })
      context.commit("cases/setCaseInformationSaveStatus", true, {
        root: true,
      })

      if (
        params.case_types.indexOf("TOXICOLOGY") > -1 ||
        params.case_types.indexOf("PGX_TEST") > -1
      ) {
        context.commit("cases/setShowMedication", true, {
          root: true,
        })
      } else {
        context.commit("cases/setShowMedication", false, {
          root: true,
        })
      }
    } catch (error) {
      context.commit("cases/setCaseInformationSaveStatus", false, {
        root: true,
      })

      if (error.response) {
        context.commit(
          "cases/setCaseInformationValidationErrorsCode",
          error.response.data,
          { root: true }
        )

        let errorData = await context.dispatch(
          "errors/errorResponse",
          error.response,
          {
            root: true,
          }
        )
        context.commit("cases/setCaseInformationValidationErrors", errorData, {
          root: true,
        })
      }
    }
  },
  updateCase: async (context, params) => {
    try {
      context.commit(
        "cases/setCaseInformationValidationErrors",
        {},
        {
          root: true,
        }
      )
      context.commit(
        "cases/setCaseInformationValidationErrorsCode",
        {},
        { root: true }
      )
      context.commit("cases/setCaseInformationSaveStatus", false, {
        root: true,
      })
      const response = await axios.patch(`/cases/${params.case_id}`, params)
      context.commit("cases/setCaseInformation", response.data.data, {
        root: true,
      })
      context.commit("cases/setCaseInformationSaveStatus", true, {
        root: true,
      })
      context.commit("cases/setPreviewCaseData", response.data.data, {
        root: true,
      })

      if (
        params.case_types.indexOf("TOXICOLOGY") > -1 ||
        params.case_types.indexOf("PGX_TEST") > -1
      ) {
        context.commit("cases/setShowMedication", true, {
          root: true,
        })
      } else {
        context.commit("cases/setShowMedication", false, {
          root: true,
        })
      }
    } catch (error) {
      context.commit("cases/setCaseInformationSaveStatus", false, {
        root: true,
      })

      if (error.response) {
        context.commit(
          "cases/setCaseInformationValidationErrorsCode",
          error.response.data,
          { root: true }
        )

        let errorData = await context.dispatch(
          "errors/errorResponse",
          error.response,
          {
            root: true,
          }
        )
        context.commit("cases/setCaseInformationValidationErrors", errorData, {
          root: true,
        })
      }
    }
  },

  fetchCaseInformation: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", [], { root: true })

      const response = await axios.get("/cases/" + params)
      commit("setCaseInformation", response.data.data)
      if (
        response.data.data.case_types.indexOf("TOXICOLOGY") > -1 ||
        response.data.data.case_types.indexOf("PGX_TEST") > -1
      ) {
        commit("setShowMedication", true)
      } else {
        commit("setShowMedication", false)
      }
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },

  setCaseInformationToNull: async (context) => {
    try {
      context.commit(
        "cases/setCaseInformation",
        {},
        {
          root: true,
        }
      )
    } catch (error) {
      console.log(error)
    }
  },

  deleteCase: async ({ commit, dispatch }, params) => {
    try {
      let case_id = params.case_id
      delete params.case_id
      commit("setCaseDeleteStatus", false)
      await axios.delete("/cases/" + case_id, {
        data: params,
      })
      commit("setCaseDeleteStatus", true)
    } catch (error) {
      if (error && error.data && error.data.message) {
        commit("setCaseDeleteError", error.data.message)
      } else {
        commit("setCaseDeleteError", "Error while deleting case")
      }
    }
  },

  fetchDocumentUploadUrl: async ({ commit, dispatch }, params) => {
    try {
      commit("setFileUploadURLForAttachments", null)
      commit("setFileUploadRefIdForAttachment", null)
      const response = await axios.post(
        "/cases/" + params.case_id + "/" + params.type,
        params
      )
      const data = response.data.data
      commit("setFileUploadURLForAttachments", data.target_url)
      commit("setFileUploadRefIdForAttachment", data.hash)
    } catch (error) {
      console.log(error)
    }
  },
  fetchFileUploadUrl: async ({ commit }, params) => {
    try {
      commit("setMultipleReportsUploadData", null)

      const response = await axios.post(
        "/cases/" + params.case_id + "/test-reports",
        params
      )
      const data = response.data.data
      commit("setMultipleReportsUploadData", data)
    } catch (error) {
      console.log(error)
    }
  },

  uploadDocumentToGeneratedUrl: async ({ commit }, params) => {
    try {
      commit("setAttachmentUploadStatus", false)
      let pAxios = axios.create({})
      await pAxios.put(params.upload_url, params.file)
      commit("setAttachmentUploadStatus", true)
    } catch (error) {
      commit("setAttachmentUploadStatus", false)
    }
  },
  uploadDocumentToGeneratedUrlForAttachments: async ({ commit }, params) => {
    try {
      commit("setAttachmentUploadStatusForAttachments", false)
      let pAxios = axios.create({})
      await pAxios.put(params.upload_url, params.file, {
        headers: {
          "Content-Type": params.file.type,
        },
      })
      commit("setAttachmentUploadStatusForAttachments", true)
    } catch (error) {
      commit("setAttachmentUploadStatusForAttachments", false)
    }
  },
  updateDocumentUploadStatus: async ({ commit, dispatch }, params) => {
    try {
      commit("setAttachmentUploadStatusUpdated", false)
      await axios.patch(
        "/cases/" +
        params.case_id +
        "/" +
        params.type +
        "/" +
        params.hash +
        "/status"
      )
      commit("setAttachmentUploadStatusUpdated", true)
    } catch (error) {
      commit("setAttachmentUploadStatusUpdated", false)
    }
  },

  deleteAttachment: async ({ commit }, params) => {
    try {
      commit("setAttachmentDeleteStatus", false)
      await axios.delete(
        "/cases/" + params.case_id + "/attachments/" + params.id,
        {
          data: params,
        }
      )
      commit("setAttachmentDeleteStatus", true)
    } catch (error) {
      commit("setAttachmentDeleteStatus", false)
    }
  },
  deleteInternalDocument: async ({ commit }, params) => {
    try {
      commit("setAttachmentDeleteStatus", false)
      await axios.delete(
        "/cases/" + params.case_id + "/internal-documents/" + params.id,
        {
          data: params,
        }
      )
      commit("setAttachmentDeleteStatus", true)
    } catch (error) {
      commit("setAttachmentDeleteStatus", false)
    }
  },

  addNote: async ({ commit }, params) => {
    try {
      commit("setNoteValidationErrors", {})
      commit("setNoteAddStatus", false)
      await axios.post("cases/" + params.case_id + "/internal-notes", params)
      commit("setNoteAddStatus", true)
    } catch (error) {
      commit("setNoteAddStatus", false)
      if (error.response) {
        const errorData = await context.dispatch(
          "errors/errorResponse",
          error.response,
          {
            root: true,
          }
        )
        commit("setNoteValidationErrors", errorData)
      }
    }
  },
  updateNote: async ({ commit }, params) => {
    try {
      commit("setNoteUpdateStatus", false)
      await axios.patch(
        "cases/" + params.case_id + "/internal-notes/" + params.id,
        params
      )
      commit("setNoteUpdateStatus", true)
    } catch (error) {
      commit("setNoteUpdateStatus", false)
      if (error.response) {
        const errorData = await context.dispatch(
          "errors/errorResponse",
          error.response,
          {
            root: true,
          }
        )
        commit("setNoteValidationErrors", errorData)
      }
    }
  },
  deleteNote: async ({ commit }, params) => {
    try {
      commit("setNoteDeleteStatus", false)
      await axios.delete(
        "cases/" + params.case_id + "/internal-notes/" + params.id,
        {
          data: params,
        }
      )
      commit("setNoteDeleteStatus", true)
    } catch (error) {
      commit("setNoteDeleteStatus", false)
      if (error.response) {
        const errorData = await context.dispatch(
          "errors/errorResponse",
          error.response,
          {
            root: true,
          }
        )
        commit("setNoteValidationErrors", errorData)
      }
    }
  },

  unsetFormValidationErrors: async ({ commit }) => {
    commit("setCaseInformationValidationErrors", {})

    commit("setNoteValidationErrors", {})
  },

  generateBarCode: async ({ commit, dispatch }, params) => {
    try {
      commit("setBarCodeGenerateStatus", false)
      const data = await axios.post(`/cases/${params.case_id}/bar-code`)
      commit("setBarCodeURL", data.data.path)
      commit("setBarCodeGenerateStatus", true)
    } catch (error) {
      commit("setBarCodeGenerateStatus", false)
      if (error.response) {
        const errorData = await dispatch("errorResponse", error.response)
        commit("setNoteValidationErrors", errorData)
      }
    }
  },
  updateUrineTestResults: async ({ commit }, params) => {
    try {
      let caseId = params.caseId
      delete params.caseId
      commit("setUrineTestResultsUpdateStatus", false)
      await axios.put(
        "/urine-test-results/" + caseId + "/bulk-update",
        params.urine_test_results
      )
      commit("setUrineTestResultsUpdateStatus", true)
    } catch (error) {
      commit("setUrineTestResultsUpdateStatus", false)
    }
  },
  createUrineTestResults: async ({ commit }, params) => {
    try {
      let caseId = params.case_id
      delete params.case_id
      commit("setResultsAddStatus", false)
      await axios.post(
        "/urine-test-results/" + caseId + "/bulk-insert",
        params.urineTestResults
      )
      commit("setResultsAddStatus", true)
    } catch (err) {
      commit("setResultsAddStatus", false)
    }
  },
  generateUrineTestReport: async ({ commit }, params) => {
    try {
      let case_id = params.case_id
      delete params.case_id

      commit("setUrineTestReportGenerateStatus", false)
      await axios.post(
        "/urine-test-results/" + case_id + "/generate-test-report",

        params
      )
      commit("setUrineTestReportGenerateStatus", true)
    } catch (err) {
      commit("setUrineTestReportGenerateStatus", false)
    }
  },
  updateTestResults: async ({ commit }, params) => {
    try {
      commit("setTestResultsUpdateStatus", false)
      await axios.patch("/blood-test-results", params)
      commit("setTestResultsUpdateStatus", true)
    } catch (error) {
      commit("setTestResultsUpdateStatus", false)
    }
  },

  fetchCaseTestReports: async ({ commit }, params) => {
    try {
      commit("setTestReportsData", [])
      const response = await axios.get(
        "/cases/" + params.case_id + "/test-reports"
      )
      commit("setTestReportsData", response.data.data)
    } catch (error) {
      commit("setTestReportsData", [])
    }
  },

  generateTestReport: async ({ commit, dispatch }, params) => {
    let accession_id = params.accession_id
    delete params.accession_id
    try {
      commit("errors/clear", null, {
        root: true,
      })
      commit("setReportGenerateStatus", false)
      const response = await axios.post(
        "/cases/" + accession_id + "/generate-test-report",
        params
      )
      commit("setTestReportURL", response.data.data.path)
      commit("setReportGenerateStatus", true)

      let url = response.data.data.location

      if (!params.skip_download && url) {
        window.open(url, "_blank")
      }
    } catch (error) {
      commit("setReportGenerateStatus", false)
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        })
      }
    }
  },
  generateBloodTestReport: async ({ commit, dispatch }, params) => {
    let case_id = params.case_id
    delete params.case_id
    try {
      commit("errors/clear", null, {
        root: true,
      })
      commit("setBloodTestReportGenerateStatus", false)
      await axios.post(
        "/blood-test-results/" + case_id + "/generate-test-report",

        params
      )
      commit("setBloodTestReportGenerateStatus", true)
    } catch (err) {
      commit("setBloodTestReportGenerateStatus", false)
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, {
          root: true,
        })
      }
    }
  },
  previewUrineTestReport: async ({ commit, dispatch }, params) => {
    try {
      commit("errors/clear", null, {
        root: true,
      })
      commit("setReportPreviewStatus", false)
      commit("setUrineTestReportPreviewURL", null)
      const response = await axios.post(
        "/urine-test-results/" + params.case_id + "/preview-report"
      )
      commit("setUrineTestReportPreviewURL", response.data)
      commit("setReportPreviewStatus", true)
    } catch (error) {
      commit("setUrineTestReportPreviewURL", null)
      commit("setReportPreviewStatus", false)
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        })
      }
    }
  },
  previewTestReport: async ({ commit, dispatch }, params) => {
    try {
      commit("errors/clear", null, {
        root: true,
      })
      commit("setReportPreviewStatus", false)
      commit("setTestReportPreviewURL", null)
      const response = await axios.post(
        "/blood-test-results/" + params.case_id + "/preview-report"
      )
      commit("setTestReportPreviewURL", response.data)
      commit("setReportPreviewStatus", true)
    } catch (error) {
      commit("setTestReportPreviewURL", null)
      commit("setReportPreviewStatus", false)
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        })
      }
    }
  },
  deleteTestReport: async ({ commit }, params) => {
    try {
      commit("setReportDeleteStatus", false)
      await axios.delete(
        "/cases/" + params.case_id + "/test-report/" + params.report_id,
        {
          data: params,
        }
      )
      commit("setReportDeleteStatus", true)
    } catch (error) {
      commit("setReportDeleteStatus", false)
    }
  },
  updateTestReportPhysicianStatus: async ({ commit, dispatch }, params) => {
    try {
      commit("setReportPhysicianStatusUpdated", false)
      commit("setReportPhysicianStatusData", null)
      commit("setReportPhysicianValiation", null)
      const { data } = await axios.patch(
        `/cases/${params.case_id}/test-report/${params.report_id}/physician-status`,
        params
      )

      commit("setReportPhysicianStatusUpdated", true)
      commit("setReportPhysicianStatusData", data.data)
    } catch (error) {
      commit("setReportPhysicianStatusUpdated", false)
      await dispatch("errors/errorResponse", error.response, {
        root: true,
      })
      commit("setReportPhysicianValiation", error.response.data)
    }
  },
  updateTestReportDownloadStatus: async ({ commit, dispatch }, params) => {
    try {
      commit("setReportDownloadStatus", false)
      await axios.post(
        "/cases/" +
        params.case_id +
        "/test-report/" +
        params.report_id +
        "/download-status",
        params
      )
      commit("setReportDownloadStatus", true)
    } catch (error) {
      commit("setReportDownloadStatus", false)
    }
  },
  updateTestReportAdditionalReports: async ({ commit }, params) => {
    try {
      let case_id = params.case_id
      delete params.case_id
      let report_id = params.report_id
      delete params.report_id
      commit("setReportAdditionalReports", false)
      await axios.post(
        "/cases/" +
        case_id +
        "/additional-test-report/" +
        report_id +
        "/additional-reports",
        params
      )
      commit("setReportAdditionalReports", true)
    } catch (error) {
      commit("setReportAdditionalReports", false)
    }
  },
  updateMergeAdditionalReports: async ({ commit }, params) => {
    try {
      let case_id = params.case_id
      delete params.case_id
      let report_id = params.report_id
      delete params.report_id
      commit("setMergeAdditionalReports", false)
      await axios.post(
        "/cases/" +
        case_id +
        "/merge-additional-test-report/" +
        report_id +
        "/merge-additional-reports"
      )
      commit("setMergeAdditionalReports", true)
    } catch (error) {
      commit("setMergeAdditionalReports", false)
    }
  },
  updateTestReportTitle: async ({ commit }, params) => {
    try {
      commit("setReportTitleUpdated", false)
      await axios.patch(
        "/cases/" +
        params.case_id +
        "/test-report/" +
        params.report_id +
        "/title",
        params
      )
      commit("setReportTitleUpdated", true)
    } catch (error) {
      commit("setReportTitleUpdated", false)
    }
  },

  changeCaseStatus: async ({ commit }, params) => {
    try {
      commit("setUpdateCaseStatus", null)
      let response = await axios.patch(
        "/cases/" + params.id + "/status",
        params
      )
      commit("setUpdateCaseStatus", response.data)
    } catch (error) {
      if (error.response) {
        console.log(error.response)
      }
    }
  },

  changeCaseCovidStatus: async ({ commit }, params) => {
    try {
      commit("setUpdateCaseCovidStatus", null)
      let response = await axios.patch(
        "/cases/" + params.id + "/covid-status",
        {
          covid_status: params.status,
        }
      )
      commit("setUpdateCaseCovidStatus", response.data)
    } catch (error) {
      if (error.response) {
        console.log(error.response)
      }
    }
  },

  downloadCaseData: async ({ commit }, params) => {
    try {
      commit("setDownloadCaseDataStatus", false)
      const response = await axios.get("/cases/download-as-csv", {
        params,
      })
      commit("setDownloadCaseDataStatus", true)
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url
      const currentDate = moment().tz("America/New_York");
      const fileName = currentDate.format('MM-DD-YYYY_hh-mm_A');
      link.setAttribute("download", `cases_${fileName}.csv`) //or any other extension
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      commit("setDownloadCaseDataStatus", false)
      console.log(error)
    }
  },
  downloadTrumedData: async ({ commit }, params) => {
    try {
      commit("setDownloadTrumedDataStatus", false)
      const response = await axios.get(
        "/trumed-pharmacy-orders/download-as-csv",
        {
          params,
        }
      )
      commit("setDownloadTrumedDataStatus", true)
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", "trumed.csv") //or any other extension
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      commit("setDownloadTrumedDataStatus", false)
      console.log(error)
    }
  },

  generateMultipleBarcodes: async ({ commit }, params) => {
    try {
      commit("setMultipleBarcodeStatus", false)
      commit("setMultipleBarcodeSources", [])
      const response = await axios.post("/cases/generate-bar-codes", params)
      commit("setMultipleBarcodeStatus", true)
      commit("setMultipleBarcodeSources", response.data.data)
    } catch (error) {
      commit("setMultipleBarcodeStatus", false)
    }
  },

  restoreArchivedCase: async ({ commit }, caseId) => {
    try {
      commit("setRestoreCaseError", null)
      commit("setRestoreCaseStatus", false)
      let status = {
        status: "ACTIVE",
      }
      await axios.patch("/cases/" + caseId + "/restoreCase", status)
      commit("setRestoreCaseStatus", true)
    } catch (err) {
      commit("setRestoreCaseStatus", false)
      if (err.response) {
        commit(
          "setRestoreCaseError",
          err.response.message || "Error at restore order"
        )
      } else {
        commit("orders/setRestoreCaseError", "Error at restore case", {
          root: true,
        })
      }
    }
  },

  resetGenerateMultipleBarcodes: async ({ commit }) => {
    commit("setMultipleBarcodeStatus", false)
    commit("setMultipleBarcodeSources", [])
  },
  fetchGlobalSearchCasesData: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", [], {
        root: true,
      })
      let response = []
      if (params) {
        response = await axios.get("/cases-search", {
          params,
        })
      } else {
        response = await axios.get("/cases-search")
      }

      commit("setGlobalSearchCases", response.data)
    } catch (err) {
      commit("setAllCaseErrors", true)
      await dispatch("errors/errorResponse", err.response, {
        root: true,
      })
    }
  },

  createMedicationData: async (context, params) => {
    try {
      context.commit("cases/setMedicationValidationErrors", null, {
        root: true,
      })
      context.commit("cases/setMedicationCreateStatus", false, {
        root: true,
      })
      let response = await axios.post("/medications", params)
      context.commit("cases/setMedicationCreateStatus", true, {
        root: true,
      })
      context.commit("cases/setNewlyAddedMedication", response.data.data, {
        root: true,
      })
    } catch (err) {
      if (err.response) {
        let errorData = await context.dispatch(
          "errors/errorResponse",
          err.response,
          {
            root: true,
          }
        )
        context.commit("cases/setMedicationValidationErrors", errorData, {
          root: true,
        })
      }
    }
  },
  fetchAllMedicationsData: async ({ commit }, params) => {
    try {
      commit("setAllMedications", null)
      let response = await axios.get("/medications", {
        params,
      })
      commit("setAllMedications", response.data.data)
    } catch (err) {
      if (err.response) {
        await context.dispatch("errors/errorResponse", err.response, {
          root: true,
        })
      }
    }
  },
  uploadMultipleTestReports: async (context, params) => {
    try {
      context.commit("cases/setUploadTestReportValidationErrors", null, {
        root: true,
      })
      context.commit(
        "cases/setUploadMultipleTestReportData",
        {},
        {
          root: true,
        }
      )
      context.commit("cases/setUploadStatus", false, { root: true })
      const response = await axios.post(
        "/cases-report-upload/" + params.case_id + "/multiple",
        params.form_data
      )
      context.commit("cases/setUploadStatus", true, {
        root: true,
      })
      context.commit(
        "cases/setUploadMultipleTestReportData",
        response.data.data,
        {
          root: true,
        }
      )
    } catch (error) {
      if (error.response) {
        let errorData = await context.dispatch(
          "errors/errorResponse",
          error.response,
          {
            root: true,
          }
        )
        context.commit("cases/setUploadTestReportValidationErrors", errorData, {
          root: true,
        })
        context.commit("cases/setUploadStatus", false, { root: true })
      }
    }
  },
  uploadTestReports: async (context, params) => {
    try {
      context.commit("cases/setUploadTestReportValidationErrors", null, {
        root: true,
      })
      context.commit(
        "cases/setUploadTestReportData",
        {},
        {
          root: true,
        }
      )
      const response = await axios.post(
        "/cases-report-upload/" + params.case_id,
        params.form_data
      )
      context.commit("cases/setUploadTestReportData", response.data.data, {
        root: true,
      })
    } catch (error) {
      if (error.response) {
        let errorData = await context.dispatch(
          "errors/errorResponse",
          error.response,
          {
            root: true,
          }
        )
        context.commit("cases/setUploadTestReportValidationErrors", errorData, {
          root: true,
        })
      }
    }
  },
  updateTestReportStatus: async ({ commit }, params) => {
    try {
      commit("setUploadTestReportStatus", false)
      await axios.patch(
        "/cases-upload/" + params.case_id + "/report/" + params.ref_id
      )
      commit("setUploadTestReportStatus", true)
    } catch (error) {
      commit("setUploadTestReportStatus", false)
    }
  },
  fetchTestReportDownloadURL: async ({ commit }, params) => {
    try {
      commit("setDownloadReportData", null)
      let response = await axios.post("/file-uploads/download-url", params)
      commit("setDownloadReportData", response.data)
    } catch (error) {
      commit("setDownloadReportData", null)
    }
  },

  mergeCaseReport: async ({ commit }, params) => {
    try {
      commit("setMergeCaseReportErrors", null)
      commit("setMergeCaseReportStatus", false)
      await axios.post(
        `/cases/${params.case_id}/test-report/${params.report_id}/merge-supplemental-report`
      )
      commit("setMergeCaseReportStatus", true)
    } catch (err) {
      if (err.response) {
        let errorData = await context.dispatch(
          "errors/errorResponse",
          err.response,
          {
            root: true,
          }
        )
        commit("setMergeCaseReportErrors", errorData)
      }
    }
  },
  updateTestReportNote: async ({ commit }, params) => {
    try {
      commit("setReportNoteUpdated", false)
      await axios.patch(
        "/cases/" +
        params.case_id +
        "/test-report/" +
        params.report_id +
        "/supplemental-notes",
        params.data
      )
      commit("setReportNoteUpdated", true)
    } catch (error) {
      commit("setReportNoteUpdated", false)
    }
  },
  fetchCoriellTestsOfPanel: async ({ commit }, { lab_id, case_type }) => {
    try {
      commit("setCorriellTestPanels", null)
      const response = await axios.get(
        `/corriel-tests/${lab_id}?case_type=${case_type}`
      )
      commit("setCorriellTestPanels", response.data)
    } catch (err) {
      commit("setCorriellTestPanels", null)
    }
  },
  deleteBillingInfo: async ({ commit }, case_id) => {
    try {
      commit("setDeleteCaseBillingInfoStatus", false)
      await axios.delete(`cases/${case_id}/billing-information`)
      commit("setDeleteCaseBillingInfoStatus", true)
    } catch (err) {
      commit("setDeleteCaseBillingInfoStatus", false)
    }
  },
  checkInsuranceEligibility: async ({ commit, dispatch }, case_id) => {
    try {
      commit("errors/clear", null, { root: true })
      commit("setEligibilityStatus", null)
      const response = await axios.post(
        `cases/${case_id}/billing-info/verify-insurance-eligibilty`
      )
      commit("setEligibilityStatus", response.data)
    } catch (err) {
      commit("setEligibilityStatus", null)
      await dispatch("errors/errorResponse", err.response, {
        root: true,
      })
    }
  },
  fetchInsuranceEligibleDetails: async ({ commit }, case_id) => {
    try {
      commit("setInsuranceEligibilityDetails", null)
      const response = await axios.get(
        `cases/${case_id}/billing-info/insurance-eligibilty-details`
      )
      commit("setInsuranceEligibilityDetails", response.data)
    } catch (err) {
      commit("setInsuranceEligibilityDetails", null)
    }
  },
  sendFax: async ({ commit, dispatch }, { case_id, report_id, params }) => {
    try {
      commit("errors/clear", null, { root: true })
      commit("setFaxSentStatus", false)
      await axios.post(
        `/cases/${case_id}/test-reports/${report_id}/send-fax`,
        params
      )
      commit("setFaxSentStatus", true)
    } catch (error) {
      commit("setFaxSentStatus", false)
      await dispatch("errors/errorResponse", error.response, {
        root: true,
      })
    }
  },
  createUTICase: async ({ commit, dispatch }, params) => {
    try {
      commit("errors/clear", null, { root: true })
      commit("setUTICaseCreationStatus", false)
      await axios.post(
        '/cases/uti-case',
        params
      )
      commit("setUTICaseCreationStatus", true)
    } catch (error) {
      commit("setUTICaseCreationStatus", false)
      await dispatch("errors/errorResponse", error.response, {
        root: true,
      })
    }
  },
  fetchTestResults: async ({ commit }, { caseId }) => {
    try {
      commit("setTestResults", null)
      const response = await axios.get(`cases/${caseId}/test-results`)
      commit("setTestResults", response.data.data)
    } catch (err) {
      commit("setTestResults", null)
    }
  },
  previewTestResults: async ({ commit }, { caseId }) => {
    try {
      commit("setTestResultsPreview", null)
      const response = await axios.get(`cases/${caseId}/test-results/preview`)
      commit("setTestResultsPreview", response.data)
    } catch (err) {
      commit("setTestResultsPreview", null)
    }
  },

  checkCaseFaxStatus: async ({ commit }, caseId) => {
    try {
      commit("setCheckFaxStatus", null)
      const { data } = await axios.get(`/cases/${caseId}/fax-status`)
      commit("setCheckFaxStatus", data.data)
    } catch (err) {
      commit("setCheckFaxStatus", null)
    }
  },
  fetchAllTestResults: async ({ commit }, params) => {
    try {
      commit("setBloodTestResults", null)
      const response = await axios.get("/blood-test-results", { params })
      commit("setBloodTestResults", response.data.response)
    } catch (err) {
      commit("setBloodTestResults", null)
    }
  },
  fetchAllUrineTestResults: async ({ commit }, { caseId }) => {
    try {
      commit("setUrineTestResults", null)
      const response = await axios.get(`urine-test-results/report/${caseId}`)
      commit("setUrineTestResults", response.data)
    } catch (err) {
      commit("setUrineTestResults", null)
    }
  },
  fetchUrineMachineResults: async ({ commit }, { accession_id }) => {
    try {
      commit("setUrineMachineResults", null)
      const response = await axios.get(
        `urinalysis-machine-logs/${accession_id}`
      )
      commit("setUrineMachineResults", response.data.data)
    } catch (err) {
      commit("setUrineMachineResults", null)
    }
  },
  resendReportToPatient: async ({ commit, dispatch }, params) => {
    try {
      commit("setReportSentToPatientStatus", false)
      commit("errors/clear", null, { root: true })
      await axios.post("/cases-send-email/patient", params)
      commit("setReportSentToPatientStatus", true)
    } catch (err) {
      commit("setReportSentToPatientStatus", false)
      await dispatch("errors/createError", err.response, { root: true })
    }
  },
  resendReportToPatientBySMS: async ({ commit, dispatch }, params) => {
    try {
      commit("setReportSentToPatientBySMSStatus", false)
      commit("errors/clear", null, { root: true })
      await axios.post("/case-send-sms/patient", params)
      commit("setReportSentToPatientBySMSStatus", true)
    } catch (err) {
      commit("setReportSentToPatientBySMSStatus", false)
      await dispatch("errors/createError", err.response, { root: true })
    }
  },
  fetchAllCity: async ({ commit }, params) => {
    try {
      const response = await axios.get("/states/cities", { params })
      const cities =
        response.data && response.data.data && response.data.data[0]
          ? response.data.data[0].cities
          : []
      commit("setAllCity", cities)
    } catch (error) {
      commit("setAllCity", [])
    }
  },

  fetchToxicResults: async ({ commit, dispatch }, caseId) => {
    try {
      await dispatch("errors/clear", [], { root: true })

      const response = await axios.get(`/toxic-results/${caseId}`)
      commit("setToxicInformation", response.data.data)
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
  createToxicTestResults: async ({ commit, dispatch }, caseId) => {
    try {
      commit("setToxResultsAddStatus", false)
      await axios.post(`/toxic-results/${caseId}/start-reporting`)
      commit("setToxResultsAddStatus", true)
    } catch (error) {
      commit("setToxResultsAddStatus", false)

      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
  updateToxicologyTestResults: async ({ commit, dispatch }, params) => {
    try {
      let sample_id = params.sample_id
      delete params.sample_id
      commit("setUpdateToxicologyTestResultStatus", false)
      await axios.patch("/toxic-results/" + sample_id, params)
      commit("setUpdateToxicologyTestResultStatus", true)
    } catch (err) {
      commit("setUpdateToxicologyTestResultStatus", false)
      if (err.response) {
        await context.dispatch("errors/errorResponse", err.response, {
          root: true,
        })
      }
    }
  },
  fetchSampleNameTestResultsData: async ({ commit, dispatch }, { case_id }) => {
    try {
      commit("setSampleNameTestResultData", null)
      const response = await axios.get(`/toxic-results/${case_id}/preview`)

      commit("setSampleNameTestResultData", response)
    } catch (error) {
      if (error.response) {
        commit("cases/setSampleNameTestResultData", null)
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        })
      }
    }
  },
  generateToxicologyReport: async ({ commit, dispatch }, caseId) => {
    try {
      commit("setToxReportGenerateStatus", false)
      await axios.post("/toxic-results/" + caseId + "/generate-report")
      commit("setToxReportGenerateStatus", true)
    } catch (error) {
      commit("setToxReportGenerateStatus", false)
      await dispatch("errors/errorResponse", error.response, {
        root: true,
      })
    }
  },
  testReportsDownloadLog: async ({ commit, dispatch }, params) => {
    try {
      commit("setTestReportDownloadLog", false)
      commit("errors/clear", null, { root: true })
      await axios.post(
        "cases/" +
        params.case_id +
        "/test-reports/" +
        params.report_id +
        "/download-logs/download",
        params
      )
      commit("setTestReportDownloadLog", true)
    } catch (err) {
      commit("setTestReportDownloadLog", false)
      await dispatch("errors/createError", err.response, { root: true })
    }
  },
  testReportsViewLog: async ({ commit, dispatch }, params) => {
    try {
      commit("setTestReportViewLog", false)
      commit("errors/clear", null, { root: true })
      await axios.post(
        "cases/" +
        params.case_id +
        "/test-reports/" +
        params.report_id +
        "/download-logs/view",
        params
      )
      commit("setTestReportViewLog", true)
    } catch (err) {
      commit("setTestReportViewLog", false)
      await dispatch("errors/createError", err.response, { root: true })
    }
  },
  sendAccessionToRXNT: async ({ commit, dispatch }, params) => {
    try {
      commit("setRXNTSendStatus", false)
      await axios.post(
        "/cases/" + params.case_id + "/billing/send-rxnt-record",
        params
      )
      commit("setRXNTSendStatus", true)
    } catch (err) {
      if (err.response) {
        commit("setRXNTSendStatus", false)
        await dispatch("errors/errorResponse", err.response, { root: true })
      }
    }
  },
  addCPTForAccession: async ({ commit, dispatch }, params) => {
    try {
      let case_id = params.case_id
      delete params.case_id
      commit("setAddCPT", false)
      await axios.post("/cases/" + case_id + "/billing/cpt-coding", params)
      commit("setAddCPT", true)
    } catch (err) {
      if (err.response) {
        commit("setAddCPT", false)
        await dispatch("errors/errorResponse", err.response, { root: true })
      }
    }
  },
  fetchCPTForAccession: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", [], { root: true })
      let response = await axios.get(
        "/cases/" + params.case_id + "/billing/cpt-coding",
        params
      )
      commit("setCPTForAccession", response.data)
    } catch (err) {
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true })
      }
    }
  },
  fetchTestinfoForCase: async ({ commit, dispatch }, case_id) => {
    try {
      await dispatch("errors/clear", [], { root: true })
      let response = await axios.get(`/cpt-test/${case_id}`)
      commit("setTestinforCase", response.data)
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
  fetchAllLabdaqPendingOrders: async ({ commit, dispatch }) => {
    try {
      await dispatch("errors/clear", [], { root: true })

      const response = await axios.get("/lab-daq/orders/auto-sync/pending")

      commit("setLabdaqPendingOrders", response.data)
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
  fetchAllLabdaqPendingResults: async ({ commit, dispatch }) => {
    try {
      await dispatch("errors/clear", [], { root: true })

      const response = await axios.get("/lab-daq/results/auto-sync/pending")

      commit("setLabdaqPendingResults", response.data)
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
  updateBillingCategory: async ({ commit, dispatch }, params) => {
    try {
      let case_id = params.case_id
      delete params.case_id
      commit("setBillingCategoryStatus", false)
      await axios.patch("/cases/" + case_id + "/billing-status", params)
      commit("setBillingCategoryStatus", true)
    } catch (err) {
      if (err.response) {
        commit("setBillingCategoryStatus", false)
        await dispatch("errors/errorResponse", err.response, { root: true })
      }
    }
  },
  fetchAllBilledCases: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", [], { root: true })

      const response = await axios.get("/billing-cases/pending", { params })

      commit("setAllBilledCases", response.data)
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
  fetchStatusBasedBilledCases: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", [], { root: true })

      const response = await axios.get("/billing-cases/active", { params })

      commit("setBillingStatusCases", response.data)
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
  fetchToxicologyTests: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", [], { root: true })

      const response = await axios.get(
        `/result-files/${params.test_result_id}/sync`
      )

      commit("setToxicologyTests", response.data.data)
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
  fetchAllValidationCases: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", [], { root: true })

      const response = await axios.get("/validation-cases", { params })

      commit("setIsValidationCases", response.data)
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
  changeCaseValidationStatus: async ({ commit }, params) => {
    try {
      commit("setUpdateValidationStatus", false)
      await axios.patch(
        "/cases/" + params.id + "/validation-status/" + params.status,
        { validation_reason: params.validation_reason }
      )
      commit("setUpdateValidationStatus", true)
    } catch (error) {
      if (error.response) {
        console.log(error.response)
      }
    }
  },
  fetchAllLabdaqInvalidOrders: async ({ commit, dispatch }) => {
    try {
      await dispatch("errors/clear", [], { root: true })

      const response = await axios.get("/lab-daq/invalid-results/pending")

      commit("setLabdaqInvalidOrders", response.data)
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
  fetchAllLabdaqCompletedOrders: async ({ commit, dispatch }) => {
    try {
      await dispatch("errors/clear", [], { root: true })

      const response = await axios.get("/lab-daq/completed-results/pending")

      commit("setLabdaqCompletedOrders", response.data)
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
  updateBulkValidations: async ({ commit, dispatch }, params) => {
    try {
      let status = params.status
      delete params.status
      commit("setBulkValidations", false)

      await axios.patch(`/cases/validation-status/${status}/bulk`, params)

      commit("setBulkValidations", true)
    } catch (error) {
      commit("setBulkValidations", false)
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
  fetchCPTCodesForUTI: async ({ commit, dispatch }, case_id) => {
    try {
      await dispatch("errors/clear", [], { root: true })

      const response = await axios.get(`/cpt-test/${case_id}/groups`)

      commit("setCptCodesForUTI", response.data)
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
  fetchPayableICDCodes: async ({ commit, dispatch }, case_id) => {
    try {
      await dispatch("errors/clear", [], { root: true })
      let response = await axios.get(`/cases/${case_id}/billing/payable-codes`)
      commit("setPayableICDCodes", response.data.data)
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
  assignBillingRepresentative: async ({ commit, dispatch }, params) => {
    try {
      let caseID = params.case_id
      delete params.case_id
      commit("setBillingAssignStatus", false)

      await axios.patch(`/cases/${caseID}/billing-info/assign`, params)

      commit("setBillingAssignStatus", true)
    } catch (error) {
      commit("setBillingAssignStatus", false)
    }
  },
  assignBulkRepresentative: async ({ commit, dispatch }, params) => {
    try {

      commit("setBulkBillingAssignStatus", false)

      await axios.patch(`/cases/billing-info/bulk-assigne`, params)

      commit("setBulkBillingAssignStatus", true)
    } catch (error) {
      commit("setBulkBillingAssignStatus", false)
    }
  },
  fetchInsuranceHistory: async ({ commit, dispatch }, patient_id) => {
    try {
      commit("setPatientHistory", [])
      await dispatch("errors/clear", [], { root: true })
      let response = await axios.get(
        `/patients/${patient_id}/case-history/billing`
      )
      commit("setPatientHistory", response.data)
    } catch (error) {
      commit("setPatientHistory", [])
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
  fetchMedicationBasedTests: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", [], { root: true })
      let response = await axios.post("/cases/tests-by-medications", params)
      commit("setMedicationTests",response.data)
    } catch (err) {
      if (err.response) {
        await dispatch("errors/errorResponse", err.response, { root: true })
      }
    }
  },    
  updateBillingInfoSwap: async ({ commit, dispatch }, params) => {
    try {
      commit("setBillingInfoSwap", false)
      await dispatch("errors/clear", [], { root: true })
      await axios.patch(
        `/cases/${params.case_id}/billing-info/swap-insurance`,{
          from:params.from
        }
      )
      commit("setBillingInfoSwap", true)
    } catch (error) {
      commit("setBillingInfoSwap", false)
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, { root: true })
      }
    }
  },
  addInsuranceData: async ({ commit }, params) => {
    commit("setInsuranceData", params)
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
